import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import gerai from "../../Assets/Projects/GerAI.png";
import infinitycare from "../../Assets/Projects/InfinityCare.png";
import turbometrics from "../../Assets/Projects/TurboMetrics.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={gerai}
              isBlog={false}
              title="GerAI"
              description="A platform built with PHP, HTML, CSS and JavaScript where users can generate articles, copies, images, documents, and use a personalized chat. The site features an interface in Portuguese, Spanish, and English"
              demoLink="https://app.gerai.pro/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={infinitycare}
              isBlog={false}
              title="InfinityCare"
              description="A platform built with PHP, HTML, CSS, and JavaScript for professionals in the health area to find clients in need of their services."
              demoLink="https://app.infinitycare.me/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={turbometrics}
              isBlog={false}
              title="TurboMetrics"
              description="A platform built with React.js and Node.js for aggregating Google Ads and Facebook Ads metrics, providing all ad analytics in one dashboard."
              // demoLink="https://app.infinitycare.me/"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
