import React from "react";
import Card from "react-bootstrap/Card";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Halan Marques </span>
            from <span className="purple"> Vancouver, Canadá.</span>
            <br />
            Currently mastering{" "}
            <span className="purple">Full-Stack Web Development at CCTB</span>
            . 🎓
            <br />
            Embracing the art of Coding 💻🛡️
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
